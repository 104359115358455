import React, { useEffect, useRef, useState, useCallback } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { map } from "lodash"
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledAlert,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import classnames from "classnames"

// emoji
import EmojiPicker from "emoji-picker-react"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import avatar1 from "../../assets/images/users/avatar-1.jpg"

// simple bar
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"

import {
  deleteMessage as onDeleteMessage,
  addMessage as onAddMessage,
  getChats as onGetChats,
  getContacts as onGetContacts,
  getGroups as onGetGroups,
  getMessages as onGetMessages,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"

import { handleSearchData } from "components/Common/searchFile"

import { connectSocket } from "components/Socket/socket"
import Cookies from "js-cookie"
import axios from "axios"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import Swal from "sweetalert2"

const formatTime = timestamp => {
  const date = new Date(timestamp)

  // Get the UTC hours and minutes
  const hours = String(date.getUTCHours()).padStart(2, "0")
  const minutes = String(date.getUTCMinutes()).padStart(2, "0")

  // Return the time in "HH:MM" format
  return `${hours}:${minutes}`
}

function getLastPath(url) {
  return url.substring(url.lastIndexOf("/") + 1)
}

function formatDate(timestamp) {
  const date = new Date(timestamp)
  const today = new Date().toDateString()
  const yesterday = new Date(Date.now() - 864e5).toDateString()

  if (date.toDateString() === today) {
    return "Hari Ini"
  } else if (date.toDateString() === yesterday) {
    return "Kemarin"
  } else {
    // Format the date as "29 Agustus 2024"
    return date.toLocaleDateString("id-ID", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })
  }
}

// Function to show the loader
export const showLoader = (title = "Loading...", text = "Please wait...") => {
  Swal.fire({
    title,
    text,
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading()
    },
  })
}

// Function to close the loader
export const closeLoader = () => {
  Swal.close()
}

// Function to show success notification
export const showSuccess = (
  title = "Success",
  text = "Operation completed successfully."
) => {
  Swal.fire({
    icon: "success",
    title,
    text,
  })
}

// Function to show error notification
export const showError = (title = "Error", text = "An error occurred.") => {
  Swal.fire({
    icon: "error",
    title,
    text,
  })
}

const WebviewChatWa = () => {
  // var link_server = process.env.REACT_APP_SERVER
  const [serverPhone, setServerPhone] = useState(6285222309564)
  const [socket, setSocket] = useState(null)
  const [currentUser, setCurrentUser] = useState(() => {
    const storedUser = localStorage.getItem("auth_user")
    return storedUser ? JSON.parse(storedUser) : null
  })
  const [isLoadingListUser, setIsLoadingListUser] = useState(true)
  const [isSendingMessage, setIsSendingMessage] = useState(false)
  const [listUser, setListUser] = useState([])
  const [isOpenLightBox, setIsOpenLightBox] = useState(false)

  const [selectedUser, setselectedUser] = useState(null)
  const [selectedUserPhoneNumber, setSelectedUserPhoneNumber] = useState(null)

  useEffect(() => {
    const socketInstance = connectSocket()
    setSocket(socketInstance)

    // Set up socket event listeners
    socketInstance.on("connect", () => {
      console.log("Socket connected")
      fetchUsers(socketInstance)
    })

    socketInstance.on("connect_error", err => {
      console.error("Socket connection error:", err.message)
    })

    socketInstance.on("whatsapp-user-list", data => {
      console.log("Received user-list data:", data)

      // Check if data is a string and parse it
      if (typeof data === "string") {
        try {
          data = JSON.parse(data)
        } catch (error) {
          console.error("Error parsing JSON:", error.message)
          data = []
        }
      } else {
        setListUser(data)
      }

      // Ensure data is an array before setting it
      if (Array.isArray(data)) {
        setListUser(data)
      } else {
        // console.error("Expected an array but received:", data)
        setListUser([]) // Handle unexpected data format
      }
    })
    // Clean up the socket listeners when the component unmounts
    return () => {
      socketInstance.off("whatsapp-user-list")
      socketInstance.off("connect")
      socketInstance.off("connect_error")
    }
  }, []) // Empty dependency array to run only on mount

  function fetchUsers(socket) {
    console.log("Attempting to fetch users")

    const payload = {
      token: Cookies.get("token"),
      user: localStorage.getItem("auth_user"),
    }

    // console.log("Emitting data:", payload)

    try {
      socket.emit("whatsapp-user-list", payload)
    } catch (error) {
      console.error("Error during fetch user list:", error.message)
    }
  }

  function fetchMessage(from_phone_number, to_phone_number) {
    var payload = {
      from_phone_number: from_phone_number,
      to_phone_number: to_phone_number,
    }

    // const socketInstance = connectSocket()
    // setSocket(socketInstance)

    if (selectedUser !== null) {
      // Listening for the 'whatsapp-user-list' event
      socket.on("whatsapp-message-history", (data, ackCallback) => {
        console.log("always on to listen message history")
        if (selectedUser == data.user.session_id) {
          console.log("selected user:", selectedUser)
          console.log("selectedUserPhoneNumber:", selectedUserPhoneNumber)
          console.log("wa-message-history:", data)
          if (typeof data.detail === "string") {
            try {
              data.detail = JSON.parse(data.detail)
            } catch (error) {
              console.error("Failed to parse data.detail:", error)
              data.detail = data.detail
            }
          } else {
            if (
              (data.user.to_phone_number == serverPhone &&
                data.user.from_phone_number == selectedUserPhoneNumber) ||
              (data.user.to_phone_number == selectedUserPhoneNumber &&
                data.user.from_phone_number == serverPhone)
            ) {
              setMessageHistory(data.detail)
            }
          }

          console.log(
            (data.user.to_phone_number == serverPhone &&
              data.user.from_phone_number == selectedUserPhoneNumber) ||
              (data.user.to_phone_number == selectedUserPhoneNumber &&
                data.user.from_phone_number == serverPhone)
          )

          // Perform any actions needed with the data (e.g., update UI)

          if (
            (data.user.to_phone_number == serverPhone &&
              data.user.from_phone_number == selectedUserPhoneNumber) ||
            (data.user.to_phone_number == selectedUserPhoneNumber &&
              data.user.from_phone_number == serverPhone)
          ) {
            // Ensure data.detail is an array before setting it
            if (Array.isArray(data.detail)) {
              setMessageHistory(data.detail)
            } else {
              // setMessageHistory(data.detail)
              console.error("Expected an array but received:", data.detail)
            }
          }
        }
      })

      // Optionally emit the request for message history
      socket.emit("whatsapp-message-history", payload)
    }
  }

  useEffect(() => {
    // Update loading state based on listUser content
    setIsLoadingListUser(listUser.length === 0)

    if (currentUser) {
      setCurrentUser(prevUser => ({
        ...prevUser,
        isActive: true,
      }))
    }

    // Update chat box user status if selectedUser is found in listUser
    if (selectedUser && listUser.length > 0) {
      const user = listUser.find(user => user.id === selectedUser)
      if (user) {
        setChat_Box_User_Status("online")
      }

      fetchMessage(currentUser.phone_number, selectedUser)
    }

    if (isLoadingListUser) {
      const timer = setTimeout(() => {
        setLoading(false)
      }, 3000) // Show spinner for 3 seconds

      return () => clearTimeout(timer) // Cleanup the timer on component unmount or when `isLoadingListUser` changes
    }
  }, [listUser, currentUser, selectedUser])
  //meta title
  document.title = "Chat | Skote - React Admin & Dashboard Template"

  const dispatch = useDispatch()

  const ChatProperties = createSelector(
    state => state.chat,
    WebviewChat => ({
      chats: WebviewChat.chats,
      groups: WebviewChat.groups,
      contacts: WebviewChat.contacts,
      messages: WebviewChat.messages,
      loading: WebviewChat.loading,
    })
  )

  const { chats, groups, contacts, messages, loading } =
    useSelector(ChatProperties)

  const [messageHistory, setMessageHistory] = useState([])
  const [isLoading, setLoading] = useState(loading)
  // const Chat_Box_Username2 = "Henry Wells"
  const [currentRoomId, setCurrentRoomId] = useState(1)
  // eslint-disable-next-line no-unused-vars
  // const currentUser = {
  //   name: "Agent Aurora",
  //   isActive: true,
  // }
  const [menu1, setMenu1] = useState(false)
  const [search_Menu, setsearch_Menu] = useState(false)
  const [settings_Menu, setsettings_Menu] = useState(false)
  const [other_Menu, setother_Menu] = useState(false)
  const [emoji, setEmoji] = useState(false)
  const [activeTab, setactiveTab] = useState("1")
  const [Chat_Box_Username, setChat_Box_Username] = useState("Nama Pelanggan")
  // eslint-disable-next-line no-unused-vars
  const [Chat_Box_User_Status, setChat_Box_User_Status] = useState("online")
  const [curMessage, setCurMessage] = useState("")
  const [imageFilename, setImageFilename] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null)
  const [fileFilename, setFileFilename] = useState(null)
  const [videoFilename, setVideoFilename] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [selectedLightbox, setSelectedLightbox] = useState(null)

  useEffect(() => {
    dispatch(onGetChats())
    dispatch(onGetGroups())
    dispatch(onGetContacts())
    dispatch(onGetMessages(currentRoomId))
  }, [onGetChats, onGetGroups, onGetContacts, onGetMessages, currentRoomId])

  //Toggle Chat Box Menus
  const toggleSearch = () => {
    setsearch_Menu(!search_Menu)
  }

  const toggleSettings = () => {
    setsettings_Menu(!settings_Menu)
  }

  const toggleOther = () => {
    setother_Menu(!other_Menu)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  //Use For Chat Box
  // const userChatOpen = chat => {
  //   setChat_Box_Username(chat.username)
  //   setChat_Box_User_Status(chat.is_active === 1 ? "online" : "offline")
  //   setCurrentRoomId(chat.roomId)
  //   setselectedUser(chat.id)
  //   dispatch(onGetMessages(chat.roomId))
  // }

  function handleChangeUser(userSelected) {
    // console.log("user selected: ", userSelected)
    console.log("handleChangeUser called")
    setChat_Box_Username(userSelected.name)
    setChat_Box_User_Status("online")
    setselectedUser(userSelected.id)
    setSelectedUserPhoneNumber(userSelected.phone_number)
  }

  function handleEndChat() {
    console.log(selectedUser)
    socket.emit("whatsapp-end-conversation", { id: selectedUser })

    setChat_Box_Username("Nama Pelanggan")
    setChat_Box_User_Status("online")
    setselectedUser(null)
    setSelectedUserPhoneNumber(null)
    setMessageHistory([])

    fetchUsers(socket)
  }

  useEffect(() => {
    if (socket) {
      var from_phone_number = currentUser.phone_number
      var to_phone_number = selectedUserPhoneNumber
      // console.log("emit message history", { to_phone_number, from_phone_number })
      console.log("user selected changed!", selectedUser)
      socket.emit("whatsapp-message-history", {
        to_phone_number,
        from_phone_number,
      })
      // fetchUsers(socket)
      fetchMessage(from_phone_number, to_phone_number)
    }
  }, [selectedUser])

  async function handleLogoutUser() {
    try {
      // Retrieve the token from cookies
      const token = Cookies.get("token")

      // Send logout request with token in headers
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/auth/logout",
        {
          id: currentUser.id,
          username: currentUser.username,
          phone_number: currentUser.phone_number,
        },
        {
          headers: {
            token: `${token}`,
            "Content-Type": "application/json",
          },
        }
      )

      const { status } = response

      if (status === 200) {
        // Emit logout event with data
        const data = {
          token: Cookies.get("token"),
          user: localStorage.getItem("auth_user"),
        }
        socket.emit("whatsapp-user-logout", data)

        // Remove token and user data from cookies and local storage
        Cookies.remove("token")
        localStorage.removeItem("auth_user")

        // Navigate to the login route
        window.location.href = "/login"
        // Optional: Handle any additional logout logic here
      } else {
        console.error("Logout failed with status:", status)
      }
    } catch (error) {
      // Handle error response
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      )
    }
  }

  const currentTime = new Date()
  const hours = currentTime.getHours()
  const minutes = currentTime.getMinutes()
  const time = `${hours}: ${minutes}`

  const sendMessage = () => {
    setIsSendingMessage(true)
    var from_phone_number = serverPhone
    var to_phone_number = selectedUserPhoneNumber
    if (
      curMessage !== "" ||
      imageFilename !== null ||
      fileFilename !== null ||
      videoFilename !== null
    ) {
      const newMessage = {
        from_phone_number: from_phone_number,
        to_phone_number: to_phone_number,
        message:
          selectedImage !== null
            ? imageFilename
            : selectedFile !== null
            ? fileFilename
            : selectedVideo !== null
            ? videoFilename
            : curMessage,
        type:
          selectedImage !== null
            ? "image"
            : selectedFile !== null
            ? "document"
            : selectedVideo !== null
            ? "video"
            : "text",
      }

      socket.emit("whatsapp-send-message", newMessage, response => {
        if (response.status == "Success") {
          // console.log("Message sent successfully!, response: ", response)
          fetchMessage(currentUser.phone_number, selectedUserPhoneNumber)
        } else {
          // console.error("Failed to send message!, response: ", response)
        }
      })
    }
    setCurMessage("")
    setImageFilename(null)
    setFileFilename(null)
    setVideoFilename(null)
    setSelectedImage(null)
    setSelectedFile(null)
    setSelectedVideo(null)
    setTimeout(() => {
      setIsSendingMessage(false)
    }, 1000) // 2-second delay
  }

  useEffect(() => {
    console.log("List user:", listUser)
  }, [listUser])

  const onKeyPress = e => {
    const { key, value } = e
    if (!isSendingMessage) {
      if (key === "Enter") {
        setCurMessage(value)
        // setDisable(true)
        sendMessage()
      }
      // fetchMessage(currentUser.id, selectedUser)
    }
  }

  //search recent user
  const searchUsers = () => {
    var input, filter, ul, li, a, i, txtValue
    input = document.getElementById("search-user")
    filter = input.value.toUpperCase()
    ul = document.getElementById("recent-list")
    li = ul.getElementsByTagName("li")
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0]
      txtValue = a.textContent || a.innerText
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = ""
      } else {
        li[i].style.display = "none"
      }
    }
  }

  const [deleteMsg, setDeleteMsg] = useState("")
  const toggle_deleMsg = id => {
    setDeleteMsg(!deleteMsg)
    dispatch(onDeleteMessage(id))
  }

  const [copyMsgAlert, setCopyMsgAlert] = useState(false)
  const copyMsg = ele => {
    var copyText = ele
      .closest(".conversation-list")
      .querySelector("p").innerHTML
    navigator.clipboard.writeText(copyText)
    setCopyMsgAlert(true)
    if (copyText) {
      setTimeout(() => {
        setCopyMsgAlert(false)
      }, 1000)
    }
  }

  // scroll simple bar
  const scrollRef = useRef(null)
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.getScrollElement().scrollTop =
        scrollRef.current.getScrollElement().scrollHeight
    }

    const socketInstance = connectSocket()
    setSocket(socketInstance)

    socketInstance.off("whatsapp-message-history")
    // console.log("message-history useEffect:", messageHistory)
  }, [messageHistory])

  useEffect(() => {
    console.log("selectedImage:", selectedImage)
    console.log("imageFilename:", imageFilename)
    console.log("selectedImage:", selectedImage)
    console.log("FileFilename:", fileFilename)
  }, [selectedImage, imageFilename, selectedFile, fileFilename])

  useEffect(() => {
    console.log("selectedUserPhoneNumber:", selectedUserPhoneNumber)
    console.log("serverPhone:", serverPhone)
  }, [selectedUserPhoneNumber])

  // emoji
  const [emojiArray, setEmojiArray] = useState("")
  const onEmojiClick = (event, emojiObject) => {
    setEmojiArray([...emojiArray, emojiObject.emoji])
    setCurMessage(curMessage + event.emoji)
    setDisable(true)
  }

  // Image upload
  const handleImageChange = event => {
    event.preventDefault()
    let reader = new FileReader()
    let file = event.target.files[0]

    reader.onloadend = () => {
      handleUploadImage(file) // Call the API to upload the image file
      setSelectedImage(reader.result) // Set the selected image preview
    }

    reader.readAsDataURL(file)
  }

  // File upload
  const handleFileChange = event => {
    event.preventDefault()
    let reader = new FileReader()
    let file = event.target.files[0]

    reader.onloadend = () => {
      handleUploadFile(file) // Call the API to upload the image file
      setSelectedFile(reader.result) // Set the selected image preview
    }

    reader.readAsDataURL(file)
  }

  // Video upload
  const handleVideoChange = event => {
    event.preventDefault()
    let reader = new FileReader()
    let file = event.target.files[0]

    reader.onloadend = () => {
      handleUploadVideo(file) // Call the API to upload the image file
      setSelectedVideo(reader.result) // Set the selected image preview
    }

    reader.readAsDataURL(file)
  }

  const handleUploadImage = async file => {
    // Remove 'blob' from the parameter name
    try {
      let formData = new FormData()
      formData.append("file", file) // Append the image file to the form data

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/upload/files-whatsapp`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the correct content type for file upload
          },
        }
      )

      const data = response.data
      // console.log("Response:", data)
      // Store the filename in state or wherever needed
      setImageFilename(data.filename)
    } catch (error) {
      // Handle error response
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      )
    }
  }

  const handleUploadFile = async file => {
    // Remove 'blob' from the parameter name
    try {
      showLoader("Uploading...", "Tunggu sampai file selesai diupload.")

      let formData = new FormData()
      formData.append("file", file) // Append the image file to the form data

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/upload/files-whatsapp`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the correct content type for file upload
          },
        }
      )

      const data = response.data
      // console.log("Response:", data)
      // Store the filename in state or wherever needed
      setFileFilename(data.filename)

      // Close loading alert and show success message
      closeLoader()
      showSuccess("Upload Successful", "File telah selesai diupload.")
    } catch (error) {
      closeLoader()
      showError(
        "Upload Gagal",
        error.response ? error.response.data : error.message
      )
      // Handle error response
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      )
    }
  }

  const handleUploadVideo = async file => {
    // Remove 'blob' from the parameter name
    try {
      showLoader("Uploading...", "Tunggu sampai video selesai diupload.")
      let formData = new FormData()
      formData.append("file", file) // Append the image file to the form data

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/upload/files-whatsapp`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the correct content type for file upload
          },
        }
      )

      const data = response.data
      // console.log("Response:", data)
      // Store the filename in state or wherever needed
      setVideoFilename(data.filename)

      // Close loading alert and show success message
      closeLoader()
      showSuccess("Upload Successful", "Video telah selesai diupload.")
    } catch (error) {
      closeLoader()
      showError(
        "Upload Gagal",
        error.response ? error.response.data : error.message
      )
      // Handle error response
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      )
    }
  }

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ paddingTop: "25px", maxHeight: "100vh" }}
      >
        <Container fluid>
          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                <div className="chat-leftsidebar me-lg-4">
                  <div>
                    <div className="py-4 border-bottom">
                      <div className="d-flex">
                        <div className="align-self-center me-3">
                          <div className="avatar-xs align-self-center me-3">
                            <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                              {currentUser.username.slice(0, 1).toUpperCase()}
                            </span>
                          </div>
                          {/* <img
                            src={avatar1}
                            className="avatar-xs rounded-circle"
                            alt=""
                          /> */}
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="font-size-15 mt-0 mb-1">
                            {currentUser.username}
                          </h5>
                          <p className="text-muted mb-0">
                            <i className="mdi mdi-circle text-success align-middle me-2" />
                            Active
                          </p>
                        </div>

                        <div>
                          <Dropdown
                            isOpen={menu1}
                            toggle={() => setMenu1(!menu1)}
                            className="chat-noti-dropdown"
                          >
                            <DropdownToggle
                              tag="a"
                              className="btn dropdown-toggle"
                            >
                              <i className="bx bx-cog"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem onClick={handleLogoutUser}>
                                Logout
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>

                    <div className="search-box chat-search-box py-4">
                      <div className="position-relative">
                        <Input
                          onKeyUp={searchUsers}
                          id="search-user"
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>

                    <div className="chat-leftsidebar-nav position-relative">
                      <Nav pills justified>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleTab("1")
                            }}
                          >
                            <i className="bx bx-chat font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Whatsapp</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab} className="py-4">
                        <TabPane tabId="1">
                          <div>
                            <h5 className="font-size-14 mb-3">Recent</h5>
                            <ul
                              className="list-unstyled chat-list"
                              id="recent-list"
                            >
                              {isLoadingListUser ? (
                                <>
                                  {/* <Spinners setLoading={setLoading} /> */}
                                  <li
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ height: "100px" }} // Adjust the height as needed
                                  >
                                    <span className="text-center d-sm-block">
                                      Tidak ada Data
                                    </span>
                                  </li>
                                </>
                              ) : (
                                <SimpleBar style={{ maxHeight: "410px" }}>
                                  {listUser.map((chat, index) => (
                                    <li
                                      key={chat.id || index}
                                      className={
                                        chat.phone_number ==
                                        selectedUserPhoneNumber
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      <a
                                        href="#"
                                        onClick={e => {
                                          e.preventDefault()
                                          handleChangeUser(chat)
                                        }}
                                      >
                                        <div className="d-flex">
                                          <div className="align-self-center me-3">
                                            <i
                                              className={
                                                "mdi mdi-circle text-success font-size-10"
                                              }
                                            />
                                          </div>

                                          <div className="avatar-xs align-self-center me-3">
                                            <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                              {chat.name
                                                .slice(0, 1)
                                                .toUpperCase()}
                                            </span>
                                          </div>

                                          <div className="flex-grow-1 overflow-hidden">
                                            <h5 className="text-truncate font-size-14 mb-1">
                                              {chat.name}
                                            </h5>
                                            <p className="text-truncate mb-0">
                                              {chat.phone_number}
                                            </p>
                                          </div>
                                          {chat.unread > 0 && (
                                            <div className="align-self-center me-3">
                                              <span
                                                className="badge bg-success float-end p-2"
                                                style={{
                                                  borderRadius: "50px",
                                                }}
                                              >
                                                {chat.unread}
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </a>
                                    </li>
                                  ))}
                                </SimpleBar>
                              )}
                            </ul>
                          </div>
                        </TabPane>

                        <TabPane tabId="2">
                          <h5 className="font-size-14 mb-3">Group</h5>
                          <ul className="list-unstyled chat-list">
                            <SimpleBar style={{ height: "410px" }}>
                              {groups &&
                                groups.map(group => (
                                  <li
                                    key={"test" + group.image}
                                    className={
                                      currentRoomId === group.roomId
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        userChatOpen(group)
                                      }}
                                    >
                                      <div className="d-flex align-items-center">
                                        <div className="avatar-xs me-3">
                                          <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                            {group.image}
                                          </span>
                                        </div>

                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14 mb-0">
                                            {group.name}
                                          </h5>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                ))}
                            </SimpleBar>
                          </ul>
                        </TabPane>

                        <TabPane tabId="3">
                          <h5 className="font-size-14 mb-3">Contact</h5>

                          <div>
                            <SimpleBar style={{ height: "410px" }}>
                              {contacts &&
                                contacts.map(contact => (
                                  <div
                                    key={"test_" + contact.category}
                                    className={
                                      contact.category === "A" ? "" : "mt-4"
                                    }
                                  >
                                    <div className="avatar-xs mb-3">
                                      <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                        {contact.category}
                                      </span>
                                    </div>

                                    <ul className="list-unstyled chat-list">
                                      {contact.child.map(array => (
                                        <li
                                          key={"test" + array.id}
                                          className={
                                            currentRoomId === array.roomId
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              userChatOpen(array)
                                            }}
                                          >
                                            <h5 className="font-size-14 mb-0">
                                              {array.name}
                                            </h5>
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ))}
                            </SimpleBar>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
                <div className="user-chat" style={{ width: "100%" }}>
                  <Card>
                    <div className="p-4 border-bottom ">
                      <Row>
                        <Col md="4" xs="9">
                          <h5 className="font-size-15 mb-1">
                            {Chat_Box_Username}
                          </h5>

                          <p className="text-muted mb-0">
                            <i
                              className={
                                Chat_Box_User_Status === "online"
                                  ? "mdi mdi-circle text-success align-middle me-2"
                                  : Chat_Box_User_Status === "intermediate"
                                  ? "mdi mdi-circle text-warning align-middle me-1"
                                  : "mdi mdi-circle align-middle me-1"
                              }
                            />
                            {Chat_Box_User_Status === "online"
                              ? "Active now"
                              : "Offline"}
                          </p>
                        </Col>
                        <Col md="8" xs="3">
                          <ul className="list-inline user-chat-nav text-end mb-0">
                            <li className="list-inline-item d-none d-sm-inline-block">
                              <Dropdown
                                isOpen={settings_Menu}
                                toggle={toggleSettings}
                                className="me-1"
                              >
                                <DropdownToggle className="btn nav-btn" tag="a">
                                  <i className="bx bx-cog" />
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    href="#"
                                    onClick={() => handleEndChat()}
                                  >
                                    End Chat
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>

                    <div>
                      <div className="chat-conversation p-3">
                        <SimpleBar ref={scrollRef} style={{ height: "60vh" }}>
                          {isLoading ? (
                            <Spinners setLoading={setLoading} />
                          ) : (
                            <ul
                              className="list-unstyled mb-0"
                              id="users-conversation"
                            >
                              {messageHistory &&
                                messageHistory.map((message, index) => {
                                  const prevMessage = messageHistory[index - 1]
                                  const currentMessageDate = new Date(
                                    message.time
                                  ).toDateString()
                                  const prevMessageDate = prevMessage
                                    ? new Date(prevMessage.time).toDateString()
                                    : null

                                  // Determine if a date stamp is needed
                                  const shouldShowDateStamp =
                                    prevMessageDate !== currentMessageDate ||
                                    index === 0

                                  return (
                                    <React.Fragment key={message.id}>
                                      {/* Date Stamp */}
                                      {shouldShowDateStamp && (
                                        <li
                                          className="date-separator"
                                          style={{
                                            position: "sticky",
                                            top: "0px", // Adjusted to align the separator properly
                                            zIndex: 10,
                                            backgroundColor: "#FFFFFF",
                                            textAlign: "center",
                                            fontWeight: "bold", // Use "bold" for better visibility
                                            width: "15%",
                                            left: "50%", // Center horizontally
                                            padding: "3px 0", // Adjusted for spacing
                                            transform: "translateX(-50%)", // Center horizontally
                                            boxSizing: "border-box", // Ensure padding and border are included in the width
                                            borderRadius: "15px",
                                          }}
                                        >
                                          <div className="date-stamp">
                                            {index === 0 &&
                                            currentMessageDate ===
                                              new Date().toDateString()
                                              ? "Hari Ini"
                                              : index === 0 &&
                                                currentMessageDate ===
                                                  new Date(
                                                    Date.now() - 864e5
                                                  ).toDateString()
                                              ? "Kemarin"
                                              : formatDate(message.time)}{" "}
                                            {/* Replace with your own date formatting function */}
                                          </div>
                                        </li>
                                      )}

                                      {/* Message */}
                                      <li
                                        className={
                                          message.from_phone_number ==
                                          currentUser.phone_number
                                            ? "right"
                                            : ""
                                        }
                                      >
                                        <div
                                          className="conversation-list"
                                          style={{
                                            minWidth: "5px",
                                            maxWidth: "400px",
                                          }}
                                        >
                                          <div className="ctext-wrap">
                                            {message.type === "image" ? (
                                              <>
                                                <img
                                                  src={message.message} // assuming `message.message` contains the image URL
                                                  alt="Image message"
                                                  style={{
                                                    maxWidth: "100%",
                                                    borderRadius: "8px",
                                                    marginBottom: "5px",
                                                    cursor: "pointer", // Add cursor pointer to indicate it's clickable
                                                  }}
                                                  onClick={() =>
                                                    setSelectedLightbox(
                                                      message.message
                                                    )
                                                  }
                                                />
                                                {selectedLightbox ===
                                                  message.message && (
                                                  <Lightbox
                                                    mainSrc={selectedLightbox}
                                                    onCloseRequest={() =>
                                                      setSelectedLightbox(null)
                                                    }
                                                    imageCaption={`${formatTime(
                                                      message.time
                                                    )}`} // Optional caption
                                                  />
                                                )}
                                                {message.caption && (
                                                  <p
                                                    style={{
                                                      textAlign:
                                                        message.from_phone_number ==
                                                        serverPhone
                                                          ? "left"
                                                          : "left",
                                                    }}
                                                  >
                                                    {message.caption}
                                                  </p>
                                                )}
                                              </>
                                            ) : message.type === "document" ? (
                                              <>
                                                <a
                                                  href={message.message}
                                                  target={"_blank"}
                                                  rel="noreferrer"
                                                  style={{
                                                    textAlign:
                                                      message.from_phone_number ==
                                                      currentUser.phone_number
                                                        ? "left"
                                                        : "left",
                                                  }}
                                                >
                                                  {getLastPath(message.message)}
                                                </a>

                                                {message.caption && (
                                                  <p
                                                    style={{
                                                      textAlign:
                                                        message.from_phone_number ==
                                                        serverPhone
                                                          ? "left"
                                                          : "left",
                                                    }}
                                                  >
                                                    {message.caption}
                                                  </p>
                                                )}
                                              </>
                                            ) : message.type === "video" ? (
                                              <>
                                                <video
                                                  controls
                                                  style={{
                                                    maxWidth: "100%",
                                                    borderRadius: "8px",
                                                    marginBottom: "5px",
                                                  }}
                                                >
                                                  <source
                                                    src={message.message}
                                                    type="video/mp4"
                                                  />
                                                  {/* You may need to adjust the type depending on your video format */}
                                                  Your browser does not support
                                                  the video tag.
                                                </video>

                                                {message.caption && (
                                                  <p
                                                    style={{
                                                      textAlign:
                                                        message.from_phone_number ==
                                                        serverPhone
                                                          ? "left"
                                                          : "left",
                                                    }}
                                                  >
                                                    {message.caption}
                                                  </p>
                                                )}
                                              </>
                                            ) : (
                                              <p
                                                style={{
                                                  textAlign:
                                                    message.from_phone_number ==
                                                    currentUser.phone_number
                                                      ? "left"
                                                      : "left",
                                                }}
                                              >
                                                {message.message}
                                              </p>
                                            )}

                                            {message.time !== 0 && (
                                              <p
                                                className="chat-time mb-0"
                                                style={{
                                                  textAlign:
                                                    message.from_phone_number ==
                                                    currentUser.phone_number
                                                      ? "left"
                                                      : "right",
                                                }}
                                              >
                                                {formatTime(message.time)}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </li>
                                    </React.Fragment>
                                  )
                                })}
                            </ul>
                          )}
                        </SimpleBar>
                      </div>
                      {selectedImage && (
                        <div className="replymessage-block mb-0 d-flex align-items-start">
                          <div className="flex-grow-1 text-center">
                            <img
                              src={selectedImage}
                              alt="select img"
                              style={{
                                width: "150px",
                                height: "auto",
                                display: "inline-block",
                              }}
                            />
                          </div>
                          <div className="flex-shrink-0">
                            <button
                              type="button"
                              id="close_toggle"
                              className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                              onClick={() => {
                                setSelectedImage(null)
                                setImageFilename(null)
                              }}
                            >
                              <i className="bx bx-x align-middle"></i>
                            </button>
                          </div>
                        </div>
                      )}

                      {selectedVideo && (
                        <div className="replymessage-block mb-0 d-flex align-items-start">
                          <div className="flex-grow-1 text-center">
                            <video
                              controls
                              style={{
                                maxWidth: "30%",
                                borderRadius: "8px",
                                marginBottom: "5px",
                              }}
                            >
                              <source src={selectedVideo} type="video/mp4" />
                              {/* You may need to adjust the type depending on your video format */}
                              Your browser does not support the video tag.
                            </video>
                          </div>
                          <div className="flex-shrink-0">
                            <button
                              type="button"
                              id="close_toggle"
                              className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                              onClick={() => {
                                setSelectedVideo(null)
                                setVideoFilename(null)
                              }}
                            >
                              <i className="bx bx-x align-middle"></i>
                            </button>
                          </div>
                        </div>
                      )}

                      {selectedFile && (
                        <div className="replymessage-block mb-0 d-flex align-items-start">
                          <div className="flex-grow-1 text-center">
                            <a
                              href={selectedFile}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {fileFilename}
                            </a>
                          </div>
                          <div className="flex-shrink-0">
                            <button
                              type="button"
                              id="close_toggle"
                              className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                              onClick={() => {
                                setSelectedFile(null)
                                setFileFilename(null)
                              }}
                            >
                              <i className="bx bx-x align-middle"></i>
                            </button>
                          </div>
                        </div>
                      )}

                      {copyMsgAlert && (
                        <UncontrolledAlert color="warning" role="alert">
                          {" "}
                          Message copied
                        </UncontrolledAlert>
                      )}
                      <div className="p-3 chat-input-section">
                        <Row>
                          <Col>
                            {(!selectedImage ||
                              !selectedFile ||
                              !selectedVideo) && (
                              <div className="position-relative">
                                <input
                                  type="text"
                                  value={curMessage}
                                  onKeyDown={onKeyPress}
                                  onChange={e => {
                                    setCurMessage(e.target.value)
                                  }}
                                  readOnly={isSendingMessage}
                                  disabled={
                                    selectedUser !== null ? false : true
                                  }
                                  className="form-control chat-input"
                                  placeholder="Enter Message..."
                                />
                                <div className="chat-input-links">
                                  <ul
                                    className="list-inline mb-0"
                                    style={{ marginTop: "10px" }}
                                  >
                                    <li className="list-inline-item">
                                      <label
                                        htmlFor="imageInput"
                                        style={{
                                          color: "#556ee6",
                                          fontSize: 16,
                                        }}
                                      >
                                        <i
                                          className="mdi mdi-file-image-outline me-1"
                                          id="Imagetooltip"
                                        />
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Imagetooltip"
                                        >
                                          Images
                                        </UncontrolledTooltip>
                                      </label>
                                      <input
                                        type="file"
                                        id="imageInput"
                                        accept="image/*"
                                        className="d-none"
                                        onChange={e => {
                                          handleImageChange(e)
                                          e.target.value = "" // Reset the input value to allow re-selection of the same file
                                        }}
                                      />
                                    </li>
                                    <li className="list-inline-item">
                                      <label
                                        htmlFor="fileInput"
                                        style={{
                                          color: "#556ee6",
                                          fontSize: 16,
                                        }}
                                      >
                                        <i
                                          className="mdi mdi-file-document-outline"
                                          id="Filetooltip"
                                        />
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Filetooltip"
                                        >
                                          Add document
                                        </UncontrolledTooltip>
                                      </label>
                                      <input
                                        type="file"
                                        id="fileInput"
                                        accept=".pdf,.doc,.docx,.xls,.xlsx"
                                        className="d-none"
                                        onChange={e => {
                                          handleFileChange(e)
                                          e.target.value = "" // Reset the input value to allow re-selection of the same file
                                        }}
                                      />
                                    </li>
                                    <li className="list-inline-item">
                                      <label
                                        htmlFor="videoInput"
                                        style={{
                                          color: "#556ee6",
                                          fontSize: 16,
                                        }}
                                      >
                                        <i
                                          className="mdi mdi-file-video-outline"
                                          id="Videotooltip"
                                        />
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="Videotooltip"
                                        >
                                          Add video
                                        </UncontrolledTooltip>
                                      </label>
                                      <input
                                        type="file"
                                        id="videoInput"
                                        accept=".mp4, .mkv, .avi, .mov, .wmv, .flv, .webm, video/*"
                                        className="d-none"
                                        onChange={e => {
                                          handleVideoChange(e)
                                          e.target.value = "" // Reset the input value to allow re-selection of the same file
                                        }}
                                      />
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )}
                          </Col>
                          <Col className="col-auto">
                            <Button
                              type="button"
                              color="primary"
                              disabled={
                                isSendingMessage || selectedUser === null
                              }
                              onClick={() => sendMessage()}
                              className="btn btn-primary btn-rounded chat-send w-md "
                            >
                              <span className="d-none d-sm-inline-block me-2">
                                Send
                              </span>{" "}
                              {isSendingMessage ? (
                                <i className="bx bx-loader bx-spin bx-rotate-90"></i>
                              ) : (
                                <i className="mdi mdi-send" />
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

WebviewChatWa.propTypes = {
  chats: PropTypes.array,
  groups: PropTypes.array,
  contacts: PropTypes.array,
  messages: PropTypes.array,
  onGetChats: PropTypes.func,
  onGetGroups: PropTypes.func,
  onGetContacts: PropTypes.func,
  onGetMessages: PropTypes.func,
  onAddMessage: PropTypes.func,
}

export default WebviewChatWa
